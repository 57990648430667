/** @jsx jsx */

import MySEO from '../components/seo'
import Link from '../components/Link'
import {jsx,Box,Flex,Heading,Container,Text} from 'theme-ui'
import Layout  from '../components/layout'

export default function Beitritt(){
return(
<Layout>
    <Flex>
    <Container style={{my:3}}>
    
    <Box><Heading sx={{color:'text',fontWeight:500}} as="h1">Beitritt</Heading></Box>
    
    
    <MySEO title="Beitritt zum Kulturnetz" description="Beitritt zum Kulturnetz Oberes Mittelrheintal" />
    
    
    
    <Box style={{maxWidth:700,p:0}}>
    
    
    
    
    <Heading sx={{color:'text'}}>Verein Kulturnetzwerk <br/>Oberes Mittelrheintal e.V. </Heading> 
    
    <Text>
    Wir freuen uns, wenn Sie unserem Verein beitreten möchten.  <br/>
    Bitte informieren Sie sich über unseren Verein hier auf dieser Webseite.
    </Text>
   <Text>
   Wir wünschen uns Mitglieder, die jenseits radikaler, menschenverachtender Überzeugungen humanistisch-demokratische Werte
    vertreten.
   </Text>
    
    </Box>
    
    <Box>
    
    <br/>
    
    
    
    <Heading sx={{color:'text'}}> Beitrittsformular downloaden</Heading>
    
    
     
     <Link style={{color:`text`}} to="./beitrittsformular.pdf"><Text>download</Text></Link>
    
    </Box>
    
    
    
    </Container>
    </Flex>
    </Layout>
)

}
